import React from 'react';
import logo from './img/Topo.png';
import fundo1 from './img/fundo_topo.png';
import fundo2 from './img/fundo_topo_verde.png';
import './Style.css';

function Header() {
    return (
        <header className="headerTop">
            <img src={fundo1} className="fundo1" alt="logo" />
            <img src={logo} className="logo" alt="logo" />
            <img src={fundo2} className="fundo2" alt="logo" />
        </header>
    );
}

export default Header;