import React from "react";
import "./Style.css";

function EnvioRealizadoErro(props) {
  return (
    <header>
      <center>
        <div className="id_envio_realizado">
          <span className="id_conteudo_envio_realizado">
            Arquivo enviado esta com problema.
          </span>
          <br />
          <br />
          <span className="id_conteudo_envio_realizado">
            Verifique o arquivo e tente novamente
          </span>
          <br />
          <br />
        </div>
      </center>
    </header>
  );
}

export default EnvioRealizadoErro;
