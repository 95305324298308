import React from "react";
import receita from "./img/Envio_de_receitas.png";
import "./Style.css";

function EnvioReceita(props) {
  return (
    <header>
      <center>
        <div className="id_envio_receita">
          <img src={receita} className="envioReceita" alt="Envio Receita" />
          {/*{props.isSubmited && <div class="success-message">props.isSubmited</div>}*/}
        </div>
      </center>
    </header>
  );
}

export default EnvioReceita;
