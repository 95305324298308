import React from "react";
import "./Style.css";
function Footer() {
  return (
    <header>
      <div className="rodape">© 2021 CLAMED Farm&aacute;cias </div>
    </header>
  );
}
export default Footer;
