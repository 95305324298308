import React, { useState } from "react";
import "./Style.css";
import Header from "./Header";
import EnvioReceita from "./EnvioReceita";
import EnvioRealizado from "./EnvioRealizado";
import EnvioRealizadoErro from "./EnvioRealizadoErro";
import ReceitaForm from "./ReceitaForm";
import Footer from "./Footer";

function App() {
  const [isSubmited, setIsSubmited] = useState();

  function formSubmited(res) {
    console.log(res);
    setIsSubmited(res);
  }

  const project = () => {
    switch (isSubmited) {
      case "erro":
        return <EnvioRealizadoErro isSubmited={isSubmited} />;
      case "sucesso":
        return <EnvioRealizado isSubmited={isSubmited} />;
      default:
        return (
          <center>
            <EnvioReceita isSubmited={isSubmited} />
            <div className="id_form">
              <ReceitaForm formSubmited={formSubmited} />
            </div>
          </center>
        );
    }
  };
  return (
    <div className="App">
      <Header />
      {project()}
      <Footer />
    </div>
  );
}

export default App;
