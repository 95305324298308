import React from "react";
import "./Style.css";
import { postDataFromApi } from "./services/api";
import { cpfMask } from "./services/mask";
import { telefoneMask } from "./services/mask";
import * as FormData from "form-data";
import { cpf } from "cpf-cnpj-validator";

class ReceitaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };
    this.handleChangeCampo1 = this.handleChangeCampo1.bind(this);
    this.handleChangeCampo2 = this.handleChangeCampo2.bind(this);
    this.handleChangeCampo3 = this.handleChangeCampo3.bind(this);
    this.handleChangeCampo4 = this.handleChangeCampo4.bind(this);
    this.handleButton = this.handleButton.bind(this);
    this.inputReference = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      ShowSuccess: false,
    };
  }

  handleChangeCampo1(event) {
    if (event) {
      if (event.target.value) {
        this.setState({ validarNome: "" });
      }
      //console.log(event.target.value);
      this.setState({ campo1: event.target.value });
    }
  }

  handleChangeCampo2(event) {
    if (event.target.value) {
      this.setState({ validarTelefone: "" });
    }
    this.setState({ campo2: telefoneMask(event.target.value) });
  }
  handleChangeCampo3(event) {
    if (event.target.value) {
      this.setState({ validarCPF: "" });
    }
    this.setState({ campo3: cpfMask(event.target.value) });
  }
  handleChangeCampo4(event) {
    this.setState({ validarArquivo: "" });
    this.setState({ campo4: event.target.files[0] });
    this.setState({ campo5: event.target.files[0].name });
  }
  handleButton(event) {
    this.inputReference.current.click();
  }
  handleSubmit(event) {
    //Validar Formul�rio
    if (!this.state.campo1) {
      this.setState({ validarNome: "ok" });
      return false;
    }
    if (!this.state.campo2) {
      this.setState({ validarTelefone: "ok" });
      return false;
    } else {
      this.setState({ validarTelefone: "" });
    }
    if (!this.state.campo3) {
      this.setState({ validarCPF: "ok" });
      return false;
    } else {
      this.setState({ validarCPF: "" });
    }
    if (!cpf.isValid(this.state.campo3)) {
      this.setState({ validarCPF: "ok" });
      return false;
    } else {
      this.setState({ validarCPF: "" });
    }
    if (!this.state.campo4) {
      this.setState({ validarArquivo: "ok" });
      return false;
    } else {
      this.setState({ validarArquivo: "" });
    }

    var dataAtual = new Date();
    var dia = dataAtual.getDate();
    var mes = dataAtual.getMonth() + 1;
    var ano = dataAtual.getFullYear();
    dataAtual = ano + "-" + mes + "-" + dia;

    //pegar extens�o do arquivo
    var ext = this.state.campo5;
    ext = ext.split(".").pop();

    let formData = new FormData();
    formData.append("nm_cliente", this.state.campo1);
    formData.append("nr_telefone", this.state.campo2);
    formData.append("nr_cpf_cnpj", this.state.campo3);
    formData.append("id_situacao", "A");
    formData.append("id_ext", ext);
    formData.append("id_rede", "PP");
    formData.append("dh_inclusao", dataAtual);
    formData.append("id_exportado", "N");
    formData.append("arquivo", this.state.campo4);
    postDataFromApi(formData).then((response)=>{

      this.props.formSubmited("sucesso");
     
     event.preventDefault();
   }).catch((error)=>{
     this.props.formSubmited("erro");
     
     event.preventDefault();
     // console.log(error)
   })
  }

  render() {
    return (
      <form>
        <div className="id_titulo_form">
          <div className="id_titulo_receita">Envie aqui sua receita</div>
        </div>
        <label>
          <span className="labelForm">Nome:</span>
          {this.state.validarNome && (
            <div className="id_validacao">Informe o campo nome</div>
          )}
          <input
            id="nome"
            type="text"
            className="formulario"
            value={this.state.campo1 || ""}
            onChange={this.handleChangeCampo1}
          />
        </label>
        <label>
          <span className="labelForm">Telefone:</span>
          {this.state.validarTelefone && (
            <div className="id_validacao">Informe o campo Telefone</div>
          )}
          <input
            type="text"
            className="formulario"
            value={this.state.campo2 || ""}
            onChange={this.handleChangeCampo2}
          />
        </label>
        <label>
          <span className="labelForm">CPF:</span>
          {this.state.validarCPF && (
            <div className="id_validacao">
              CPF invalido, informe um CPF valido.
            </div>
          )}
          <input
            type="text"
            className="formulario"
            value={this.state.campo3 || ""}
            onChange={this.handleChangeCampo3}
          />
        </label>
        <div className="id_anexar">
          <div id="img_anexar" className="img_anexar">
            <input
              type="file"
              name="file"
              className="arquivo"
              ref={this.inputReference}
              onChange={this.handleChangeCampo4}
            />
            <input
              type="button"
              className="btSelecionarArquivo"
              onClick={this.handleButton}
              value="Selecione o arquivo da receita"
            />
          </div>
        </div>
        <br />
        {this.state.validarArquivo && (
          <center>
            <div className="id_mensagem_arquivo">Selecione um arquivo</div>
          </center>
        )}
        {this.state.campo4 && (
          <center>
            <div className="id_mensagem">
              Arquivo selecionado com sucesso. Para enviar, clique em Cadastrar.
            </div>
          </center>
        )}
        <div className="id_cadastrar">
          <input
            type="button"
            onClick={this.handleSubmit}
            className="idBotao"
            value="Cadastrar"
          />
        </div>
      </form>
    );
  }
}

export default ReceitaForm;
