import axios from "axios";

const token = process.env.REACT_APP_TOKEN;

export function postDataFromApi(data) {
  return new Promise((resolver,reject)=>{
    const headers = {
      "X-Token": token,
      "Content-Type": "multipart/form-data",
    };
  
    axios({
      method: "post",
      url: process.env.REACT_APP_URL_API + "/postFormularioReceita",
      data: data,
      headers: headers,
    })
    .then((response) => {
      if(response.status === 200){
        resolver(response.data)
      }else{
        reject(response.data)
      }
      
    })
    .catch((error)=>{
      reject(error)
    })    
  }) 
}
